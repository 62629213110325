.flex-v {
    display: 'flex'
}

.flex-1 {
    flex: 1
}

.drawer-w {
    width: 360px;
}
.drawer-c {
    padding: 16px !important;
}

.form-s {
    padding-bottom: 32px;
}

.drawer-toolbar {
    display: flex;
    align-items: center;
    width: 100%;
}

.gap-h {
    width: 30px;
}